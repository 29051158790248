import { useState, useEffect } from 'react'
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  limit,
  orderBy
} from 'firebase/firestore'

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'

import firebaseApp from './firebase'
const db = getFirestore(firebaseApp)

const IMDB_API_KEY = process.env.REACT_APP_IMDB_API_KEY

const TopMovies = ({ size = 5 }) => {
  const [top, setTop] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const q = query(collection(db, 'movies'), where('not_seen', 'array-contains', 'Anna'), limit(size), orderBy('mentions', 'desc'))
    getDocs(q).then(snap => {
      const movies = []
      const promises = []
      snap.forEach(doc => {
        promises.push(fetch(`https://imdb-api.com/en/API/Title/${IMDB_API_KEY}/${doc.data().imdb_id}`).then(res => {
          return res.json()
        }).then(data => {
          movies.push({ ...data, mentions: doc.data().mentions })
        }))
      })
      Promise.all(promises).then(() => {
        movies.sort((a, b) => {
          if (a.mentions > b.mentions) return -1
          else return 1
        })
        setTop(movies)
        setLoading(false)
      })
    })
  }, [size])

    return (
      <>
        {loading ? (
          <Grid container alignItems='center' justifyContent='center' sx={{ width: '100ch', padding: 10 }}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <ImageList variant="masonry" cols={3} gap={8}>
            {top.map((movie, i) => (
              <ImageListItem key={i} cols={1} rows={1}>
                <img
                  src={`${movie.image}?w=200&fit=crop&auto=format`}
                  srcSet={`${movie.image}?w=200&fit=crop&auto=format&dpr=2 2x`}
                  alt={movie.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={movie.title}
                  subtitle={`Mentioned ${movie.mentions} times`}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )
      }
    </>
  )
}

export default TopMovies
