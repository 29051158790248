import { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  addDoc,
  limit,
  Timestamp
} from 'firebase/firestore'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import firebaseApp from './firebase'
const db = getFirestore(firebaseApp)

const people = [
  'Anna',
  'Cameron',
  'Kenton',
  'Ty',
  'Victoria'
]

const MentionForm = ({ open, handleClose, title, image, id }) => {
  const [seen, setSeen] = useState([])
  const [mentions, setMentions] = useState((0))
  const [docRef, setDocRef] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'movies'), where('imdb_id', '==', id), limit(1))
      const snap = await getDocs(q)
      snap.forEach(doc => {
        setDocRef(doc.ref)
        setSeen(doc.data().seen)
        setMentions(doc.data().mentions)
      })
    }
    fetchData()
  }, [open, id])

  const setMovie = async (newSeen) => {
    const notSeen = []
    people.forEach(person => {
      if (!newSeen.includes(person)) notSeen.push(person)
    })
    if (docRef) {
      await setDoc(docRef, {
        seen: newSeen,
        not_seen: notSeen,
        mentions: mentions + 1
      }, { merge: true })
    } else {
      await addDoc(collection(db, 'movies'), {
        imdb_id: id,
        title: title,
        first_mentioned: Timestamp.fromDate(new Date()),
        seen: newSeen,
        not_seen: notSeen,
        mentions: 1
      })
    }
    setMentions(0)
    setSeen([])
    setDocRef(null)
    handleClose()
  }

  const form = (
    <Form>
      <Stack>
        {people.map((person, i) => (
          <label key={i}>
            <Field type="checkbox" name='seen' value={person}/>
            {person}
          </label>
        ))}
      </Stack>
    </Form>
  )

  return(
    <Formik
      initialValues={{ seen: seen }}
      onSubmit={(values, { setSubmitting }) => {
        setMovie(values.seen)
        setSubmitting(false)
      }}
      enableReinitialize={ true }
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Who has seen {title}?</DialogTitle>
          <DialogContent>
            <DialogContentText mb={1}>
              Mentioned <strong>{mentions}</strong> times
            </DialogContentText>
            <Grid container sx={{ minWidth: 500 }} spacing={2} justifyContent='flex-start'>
              {image ? (
                <>
                  <Grid item xs={4}>
                    <img
                      src={image}
                      style={{
                        maxWidth: 150
                      }}
                      alt={title}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    {form}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    {form}
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Mention</Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default MentionForm
