import { useState, useEffect } from 'react'
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  limit,
  orderBy
} from 'firebase/firestore'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';

import SearchBar from './SearchBar'
import MentionForm from './MentionForm'
import TopMovies from './TopMovies'

import firebaseApp from './firebase'
const db = getFirestore(firebaseApp)

function App() {
  const [annaMovies, setAnnaMovies] = useState(null)
  const [formOpen, setFormOpen] = useState(false)
  const [selectedMovie, setSelectedMovie] = useState({})
  const [daysSince, setDaysSince] = useState(null)

  useEffect(() => {
    const q = query(collection(db, 'movies'), where('not_seen', 'array-contains', 'Anna'))
    onSnapshot(q, snap => {
      setAnnaMovies(snap.size)
    })
    const daysQ = query(
      collection(db, 'movies'),
      where('not_seen', 'array-contains', 'Anna'),
      orderBy('first_mentioned', "desc"),
      limit(1)
    )
    onSnapshot(daysQ, snap => {
      snap.forEach(doc => {
        const diff = new Date().getTime() - doc.data().first_mentioned.toDate().getTime()
        setDaysSince(Math.floor(diff / (1000 * 3600 * 24)))
      })
    })
  }, [])

  return (
    <>
      <Box sx={{ padding: 10, backgroundColor: 'primary.dark' }}>
        <Typography sx={{ color: 'white' }} component="div" variant="h1">Anna hasn't seen {annaMovies} movies</Typography>
        <Typography sx={{ color: 'white' }} component="div" variant="h6">Days since Anna hasn't known a movie: {daysSince}</Typography>
    </Box>
      <MentionForm title={selectedMovie.title} image={selectedMovie.image} id={selectedMovie.id} open={formOpen} handleClose={() => setFormOpen(false)} />
      <Grid container spacing={2} sx={{ padding: 5, backgroundColor: '#fcfcfc' }}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="h4">Top Mentioned Movies Anna Hasn't Seen</Typography>
            <hr />
            <TopMovies size={6} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <SearchBar
            handleMention={(id, title, image) => {
              setSelectedMovie({ title: title, id: id, image: image })
              setFormOpen(true)
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default App;
