import { useState } from 'react'

import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'

import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import Icon from '@mui/material/Icon'
import SearchIcon from '@mui/icons-material/Search';

const IMDB_API_KEY = process.env.REACT_APP_IMDB_API_KEY

const SearchBar = ({ handleMention }) => {

  const [search, setSearch] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
  }

  const searchMovies = () => {
    setLoading(true)
    fetch(`https://imdb-api.com/en/API/SearchMovie/${IMDB_API_KEY}/${search}`).then(res => {
      return res.json()
    }).then(data => {
      setSearchResults(data.results)
      setLoading(false)
    })
  }

  return (
    <>
      <FormControl sx={{ width: '100%' }} variant="standard">
        <Input
          id="standard-adornment"
          type='text'
          value={search}
          onChange={handleSearchChange}
          onKeyDown={(e) => {
            if(e.key === 'Enter' && search.length !== 0) {
             searchMovies()
            }
          }}
          placeholder='Search movies'
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={searchMovies}
                disabled={search.length === 0}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {loading ? (
        <Grid container alignItems='center' justifyContent='center' sx={{ width: '100ch', padding: 10 }}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <ImageList sx={{ width: '100%' }} variant="masonry" cols={3} gap={8}>
          {searchResults.map((movie, i) => (
            <ImageListItem key={i} cols={1} rows={1}>
              <img
                src={`${movie.image}?w=248&fit=crop&auto=format`}
                srcSet={`${movie.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={movie.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={movie.title}
                subtitle={movie.description}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${movie.title}`}
                    onClick={() => handleMention(movie.id, movie.title, movie.image)}
                  >
                    <Icon baseClassName="fas" className="fa-plus-circle" fontSize="small" />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </>
  )
}

export default SearchBar
